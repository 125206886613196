.spinner_box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.circle_border {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #00000000;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 33%, rgba(0, 0, 0, 1) 100%);
    animation: spin 0.8s linear 0s infinite;
}

.circle_core {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(359deg);
    }
}