.fp {
    position: relative;
    display: inline-block;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.fp:before {
    content: "\00a0";
}

.fp {
    line-height: 1em;
    width: 1em;
}

.fp.fp-square {
    line-height: 1em;
    width: 1em;
}

.fp.fp-rounded {
    border-radius: 50%;
}

.fp.fp-md {
    line-height: 1.5em;
    width: 1.5em;
}

.fp.fp-md.fp-square {
    line-height: 1.5em;
    width: 1.5em;
}

.fp.fp-lg {
    line-height: 2em;
    width: 2em;
}

.fp.fp-lg.fp-square {
    line-height: 2em;
    width: 2em;
}

.ac {
    background-image: url("/flags/1x1/ac.svg");
}

.ac {
    background-image: url("/flags/1x1/ac.svg");
}

.ad {
    background-image: url("/flags/1x1/ad.svg");
}

.ad {
    background-image: url("/flags/1x1/ad.svg");
}

.ae {
    background-image: url("/flags/1x1/ae.svg");
}

.ae {
    background-image: url("/flags/1x1/ae.svg");
}

.af {
    background-image: url("/flags/1x1/af.svg");
}

.af {
    background-image: url("/flags/1x1/af.svg");
}

.ag {
    background-image: url("/flags/1x1/ag.svg");
}

.ag {
    background-image: url("/flags/1x1/ag.svg");
}

.ai {
    background-image: url("/flags/1x1/ai.svg");
}

.ai {
    background-image: url("/flags/1x1/ai.svg");
}

.al {
    background-image: url("/flags/1x1/al.svg");
}

.al {
    background-image: url("/flags/1x1/al.svg");
}

.am {
    background-image: url("/flags/1x1/am.svg");
}

.am {
    background-image: url("/flags/1x1/am.svg");
}

.ao {
    background-image: url("/flags/1x1/ao.svg");
}

.ao {
    background-image: url("/flags/1x1/ao.svg");
}

.ar {
    background-image: url("/flags/1x1/ar.svg");
}

.ar {
    background-image: url("/flags/1x1/ar.svg");
}

.as {
    background-image: url("/flags/1x1/as.svg");
}

.as {
    background-image: url("/flags/1x1/as.svg");
}

.at {
    background-image: url("/flags/1x1/at.svg");
}

.at {
    background-image: url("/flags/1x1/at.svg");
}

.au {
    background-image: url("/flags/1x1/au.svg");
}

.au {
    background-image: url("/flags/1x1/au.svg");
}

.aw {
    background-image: url("/flags/1x1/aw.svg");
}

.aw {
    background-image: url("/flags/1x1/aw.svg");
}

.ax {
    background-image: url("/flags/1x1/ax.svg");
}

.ax {
    background-image: url("/flags/1x1/ax.svg");
}

.az {
    background-image: url("/flags/1x1/az.svg");
}

.az {
    background-image: url("/flags/1x1/az.svg");
}

.ba {
    background-image: url("/flags/1x1/ba.svg");
}

.ba {
    background-image: url("/flags/1x1/ba.svg");
}

.bb {
    background-image: url("/flags/1x1/bb.svg");
}

.bb {
    background-image: url("/flags/1x1/bb.svg");
}

.bd {
    background-image: url("/flags/1x1/bd.svg");
}

.bd {
    background-image: url("/flags/1x1/bd.svg");
}

.be {
    background-image: url("/flags/1x1/be.svg");
}

.be {
    background-image: url("/flags/1x1/be.svg");
}

.bf {
    background-image: url("/flags/1x1/bf.svg");
}

.bf {
    background-image: url("/flags/1x1/bf.svg");
}

.bg {
    background-image: url("/flags/1x1/bg.svg");
}

.bg {
    background-image: url("/flags/1x1/bg.svg");
}

.bh {
    background-image: url("/flags/1x1/bh.svg");
}

.bh {
    background-image: url("/flags/1x1/bh.svg");
}

.bi {
    background-image: url("/flags/1x1/bi.svg");
}

.bi {
    background-image: url("/flags/1x1/bi.svg");
}

.bj {
    background-image: url("/flags/1x1/bj.svg");
}

.bj {
    background-image: url("/flags/1x1/bj.svg");
}

.bl {
    background-image: url("/flags/1x1/bl.svg");
}

.bl {
    background-image: url("/flags/1x1/bl.svg");
}

.bm {
    background-image: url("/flags/1x1/bm.svg");
}

.bm {
    background-image: url("/flags/1x1/bm.svg");
}

.bn {
    background-image: url("/flags/1x1/bn.svg");
}

.bn {
    background-image: url("/flags/1x1/bn.svg");
}

.bo {
    background-image: url("/flags/1x1/bo.svg");
}

.bo {
    background-image: url("/flags/1x1/bo.svg");
}

.bq {
    background-image: url("/flags/1x1/bq.svg");
}

.bq {
    background-image: url("/flags/1x1/bq.svg");
}

.br {
    background-image: url("/flags/1x1/br.svg");
}

.br {
    background-image: url("/flags/1x1/br.svg");
}

.bs {
    background-image: url("/flags/1x1/bs.svg");
}

.bs {
    background-image: url("/flags/1x1/bs.svg");
}

.bt {
    background-image: url("/flags/1x1/bt.svg");
}

.bt {
    background-image: url("/flags/1x1/bt.svg");
}

.bv {
    background-image: url("/flags/1x1/bv.svg");
}

.bv {
    background-image: url("/flags/1x1/bv.svg");
}

.bw {
    background-image: url("/flags/1x1/bw.svg");
}

.bw {
    background-image: url("/flags/1x1/bw.svg");
}

.by {
    background-image: url("/flags/1x1/by.svg");
}

.by {
    background-image: url("/flags/1x1/by.svg");
}

.bz {
    background-image: url("/flags/1x1/bz.svg");
}

.bz {
    background-image: url("/flags/1x1/bz.svg");
}

.ca {
    background-image: url("/flags/1x1/ca.svg");
}

.ca {
    background-image: url("/flags/1x1/ca.svg");
}

.cc {
    background-image: url("/flags/1x1/cc.svg");
}

.cc {
    background-image: url("/flags/1x1/cc.svg");
}

.cd {
    background-image: url("/flags/1x1/cd.svg");
}

.cd {
    background-image: url("/flags/1x1/cd.svg");
}

.cf {
    background-image: url("/flags/1x1/cf.svg");
}

.cf {
    background-image: url("/flags/1x1/cf.svg");
}

.cg {
    background-image: url("/flags/1x1/cg.svg");
}

.cg {
    background-image: url("/flags/1x1/cg.svg");
}

.ch {
    background-image: url("/flags/1x1/ch.svg");
}

.ch {
    background-image: url("/flags/1x1/ch.svg");
}

.ci {
    background-image: url("/flags/1x1/ci.svg");
}

.ci {
    background-image: url("/flags/1x1/ci.svg");
}

.ck {
    background-image: url("/flags/1x1/ck.svg");
}

.ck {
    background-image: url("/flags/1x1/ck.svg");
}

.cl {
    background-image: url("/flags/1x1/cl.svg");
}

.cl {
    background-image: url("/flags/1x1/cl.svg");
}

.cm {
    background-image: url("/flags/1x1/cm.svg");
}

.cm {
    background-image: url("/flags/1x1/cm.svg");
}

.cn {
    background-image: url("/flags/1x1/cn.svg");
}

.cn {
    background-image: url("/flags/1x1/cn.svg");
}

.co {
    background-image: url("/flags/1x1/co.svg");
}

.co {
    background-image: url("/flags/1x1/co.svg");
}

.cr {
    background-image: url("/flags/1x1/cr.svg");
}

.cr {
    background-image: url("/flags/1x1/cr.svg");
}

.cu {
    background-image: url("/flags/1x1/cu.svg");
}

.cu {
    background-image: url("/flags/1x1/cu.svg");
}

.cv {
    background-image: url("/flags/1x1/cv.svg");
}

.cv {
    background-image: url("/flags/1x1/cv.svg");
}

.cw {
    background-image: url("/flags/1x1/cw.svg");
}

.cw {
    background-image: url("/flags/1x1/cw.svg");
}

.cx {
    background-image: url("/flags/1x1/cx.svg");
}

.cx {
    background-image: url("/flags/1x1/cx.svg");
}

.cy {
    background-image: url("/flags/1x1/cy.svg");
}

.cy {
    background-image: url("/flags/1x1/cy.svg");
}

.cz {
    background-image: url("/flags/1x1/cz.svg");
}

.cz {
    background-image: url("/flags/1x1/cz.svg");
}

.de {
    background-image: url("/flags/1x1/de.svg");
}

.de {
    background-image: url("/flags/1x1/de.svg");
}

.dj {
    background-image: url("/flags/1x1/dj.svg");
}

.dj {
    background-image: url("/flags/1x1/dj.svg");
}

.dk {
    background-image: url("/flags/1x1/dk.svg");
}

.dk {
    background-image: url("/flags/1x1/dk.svg");
}

.dm {
    background-image: url("/flags/1x1/dm.svg");
}

.dm {
    background-image: url("/flags/1x1/dm.svg");
}

.do {
    background-image: url("/flags/1x1/do.svg");
}

.do {
    background-image: url("/flags/1x1/do.svg");
}

.dz {
    background-image: url("/flags/1x1/dz.svg");
}

.dz {
    background-image: url("/flags/1x1/dz.svg");
}

.ec {
    background-image: url("/flags/1x1/ec.svg");
}

.ec {
    background-image: url("/flags/1x1/ec.svg");
}

.ee {
    background-image: url("/flags/1x1/ee.svg");
}

.ee {
    background-image: url("/flags/1x1/ee.svg");
}

.eg {
    background-image: url("/flags/1x1/eg.svg");
}

.eg {
    background-image: url("/flags/1x1/eg.svg");
}

.eh {
    background-image: url("/flags/1x1/eh.svg");
}

.eh {
    background-image: url("/flags/1x1/eh.svg");
}

.er {
    background-image: url("/flags/1x1/er.svg");
}

.er {
    background-image: url("/flags/1x1/er.svg");
}

.es {
    background-image: url("/flags/1x1/es.svg");
}

.es {
    background-image: url("/flags/1x1/es.svg");
}

.et {
    background-image: url("/flags/1x1/et.svg");
}

.et {
    background-image: url("/flags/1x1/et.svg");
}

.eu {
    background-image: url("/flags/1x1/eu.svg");
}

.eu {
    background-image: url("/flags/1x1/eu.svg");
}

.fi {
    background-image: url("/flags/1x1/fi.svg");
}

.fi {
    background-image: url("/flags/1x1/fi.svg");
}

.fj {
    background-image: url("/flags/1x1/fj.svg");
}

.fj {
    background-image: url("/flags/1x1/fj.svg");
}

.fk {
    background-image: url("/flags/1x1/fk.svg");
}

.fk {
    background-image: url("/flags/1x1/fk.svg");
}

.fm {
    background-image: url("/flags/1x1/fm.svg");
}

.fm {
    background-image: url("/flags/1x1/fm.svg");
}

.fo {
    background-image: url("/flags/1x1/fo.svg");
}

.fo {
    background-image: url("/flags/1x1/fo.svg");
}

.fr {
    background-image: url("/flags/1x1/fr.svg");
}

.fr {
    background-image: url("/flags/1x1/fr.svg");
}

.ga {
    background-image: url("/flags/1x1/ga.svg");
}

.ga {
    background-image: url("/flags/1x1/ga.svg");
}

.en,
.en-gb {
    background-image: url("/flags/1x1/gb.svg");
}

.gb-eng {
    background-image: url("/flags/1x1/gb-eng.svg");
}

.gb-eng {
    background-image: url("/flags/1x1/gb-eng.svg");
}

.gb-nir {
    background-image: url("/flags/1x1/gb-nir.svg");
}

.gb-nir {
    background-image: url("/flags/1x1/gb-nir.svg");
}

.gb-sct {
    background-image: url("/flags/1x1/gb-sct.svg");
}

.gb-sct {
    background-image: url("/flags/1x1/gb-sct.svg");
}

.gb-wls {
    background-image: url("/flags/1x1/gb-wls.svg");
}

.gb-wls {
    background-image: url("/flags/1x1/gb-wls.svg");
}

.gb-zet {
    background-image: url("/flags/1x1/gb-zet.svg");
}

.gb-zet {
    background-image: url("/flags/1x1/gb-zet.svg");
}

.gb {
    background-image: url("/flags/1x1/gb.svg");
}

.gb {
    background-image: url("/flags/1x1/gb.svg");
}

.gd {
    background-image: url("/flags/1x1/gd.svg");
}

.gd {
    background-image: url("/flags/1x1/gd.svg");
}

.ge {
    background-image: url("/flags/1x1/ge.svg");
}

.ge {
    background-image: url("/flags/1x1/ge.svg");
}

.gf {
    background-image: url("/flags/1x1/gf.svg");
}

.gf {
    background-image: url("/flags/1x1/gf.svg");
}

.gg {
    background-image: url("/flags/1x1/gg.svg");
}

.gg {
    background-image: url("/flags/1x1/gg.svg");
}

.gh {
    background-image: url("/flags/1x1/gh.svg");
}

.gh {
    background-image: url("/flags/1x1/gh.svg");
}

.gi {
    background-image: url("/flags/1x1/gi.svg");
}

.gi {
    background-image: url("/flags/1x1/gi.svg");
}

.gl {
    background-image: url("/flags/1x1/gl.svg");
}

.gl {
    background-image: url("/flags/1x1/gl.svg");
}

.gm {
    background-image: url("/flags/1x1/gm.svg");
}

.gm {
    background-image: url("/flags/1x1/gm.svg");
}

.gn {
    background-image: url("/flags/1x1/gn.svg");
}

.gn {
    background-image: url("/flags/1x1/gn.svg");
}

.gp {
    background-image: url("/flags/1x1/gp.svg");
}

.gp {
    background-image: url("/flags/1x1/gp.svg");
}

.gq {
    background-image: url("/flags/1x1/gq.svg");
}

.gq {
    background-image: url("/flags/1x1/gq.svg");
}

.gr {
    background-image: url("/flags/1x1/gr.svg");
}

.gr {
    background-image: url("/flags/1x1/gr.svg");
}

.gs {
    background-image: url("/flags/1x1/gs.svg");
}

.gs {
    background-image: url("/flags/1x1/gs.svg");
}

.gt {
    background-image: url("/flags/1x1/gt.svg");
}

.gt {
    background-image: url("/flags/1x1/gt.svg");
}

.gu {
    background-image: url("/flags/1x1/gu.svg");
}

.gu {
    background-image: url("/flags/1x1/gu.svg");
}

.gw {
    background-image: url("/flags/1x1/gw.svg");
}

.gw {
    background-image: url("/flags/1x1/gw.svg");
}

.gy {
    background-image: url("/flags/1x1/gy.svg");
}

.gy {
    background-image: url("/flags/1x1/gy.svg");
}

.hk {
    background-image: url("/flags/1x1/hk.svg");
}

.hk {
    background-image: url("/flags/1x1/hk.svg");
}

.hm {
    background-image: url("/flags/1x1/hm.svg");
}

.hm {
    background-image: url("/flags/1x1/hm.svg");
}

.hn {
    background-image: url("/flags/1x1/hn.svg");
}

.hn {
    background-image: url("/flags/1x1/hn.svg");
}

.hr {
    background-image: url("/flags/1x1/hr.svg");
}

.hr {
    background-image: url("/flags/1x1/hr.svg");
}

.ht {
    background-image: url("/flags/1x1/ht.svg");
}

.ht {
    background-image: url("/flags/1x1/ht.svg");
}

.hu {
    background-image: url("/flags/1x1/hu.svg");
}

.hu {
    background-image: url("/flags/1x1/hu.svg");
}

.id {
    background-image: url("/flags/1x1/id.svg");
}

.id {
    background-image: url("/flags/1x1/id.svg");
}

.ie {
    background-image: url("/flags/1x1/ie.svg");
}

.ie {
    background-image: url("/flags/1x1/ie.svg");
}

.il {
    background-image: url("/flags/1x1/il.svg");
}

.il {
    background-image: url("/flags/1x1/il.svg");
}

.im {
    background-image: url("/flags/1x1/im.svg");
}

.im {
    background-image: url("/flags/1x1/im.svg");
}

.in {
    background-image: url("/flags/1x1/in.svg");
}

.in {
    background-image: url("/flags/1x1/in.svg");
}

.io {
    background-image: url("/flags/1x1/io.svg");
}

.io {
    background-image: url("/flags/1x1/io.svg");
}

.iq {
    background-image: url("/flags/1x1/iq.svg");
}

.iq {
    background-image: url("/flags/1x1/iq.svg");
}

.ir {
    background-image: url("/flags/1x1/ir.svg");
}

.ir {
    background-image: url("/flags/1x1/ir.svg");
}

.is {
    background-image: url("/flags/1x1/is.svg");
}

.is {
    background-image: url("/flags/1x1/is.svg");
}

.it {
    background-image: url("/flags/1x1/it.svg");
}

.it {
    background-image: url("/flags/1x1/it.svg");
}

.je {
    background-image: url("/flags/1x1/je.svg");
}

.je {
    background-image: url("/flags/1x1/je.svg");
}

.jm {
    background-image: url("/flags/1x1/jm.svg");
}

.jm {
    background-image: url("/flags/1x1/jm.svg");
}

.jo {
    background-image: url("/flags/1x1/jo.svg");
}

.jo {
    background-image: url("/flags/1x1/jo.svg");
}

.jp {
    background-image: url("/flags/1x1/jp.svg");
}

.jp {
    background-image: url("/flags/1x1/jp.svg");
}

.ke {
    background-image: url("/flags/1x1/ke.svg");
}

.ke {
    background-image: url("/flags/1x1/ke.svg");
}

.kg {
    background-image: url("/flags/1x1/kg.svg");
}

.kg {
    background-image: url("/flags/1x1/kg.svg");
}

.kh {
    background-image: url("/flags/1x1/kh.svg");
}

.kh {
    background-image: url("/flags/1x1/kh.svg");
}

.ki {
    background-image: url("/flags/1x1/ki.svg");
}

.ki {
    background-image: url("/flags/1x1/ki.svg");
}

.km {
    background-image: url("/flags/1x1/km.svg");
}

.km {
    background-image: url("/flags/1x1/km.svg");
}

.kn {
    background-image: url("/flags/1x1/kn.svg");
}

.kn {
    background-image: url("/flags/1x1/kn.svg");
}

.kp {
    background-image: url("/flags/1x1/kp.svg");
}

.kp {
    background-image: url("/flags/1x1/kp.svg");
}

.kr {
    background-image: url("/flags/1x1/kr.svg");
}

.kr {
    background-image: url("/flags/1x1/kr.svg");
}

.kw {
    background-image: url("/flags/1x1/kw.svg");
}

.kw {
    background-image: url("/flags/1x1/kw.svg");
}

.ky {
    background-image: url("/flags/1x1/ky.svg");
}

.ky {
    background-image: url("/flags/1x1/ky.svg");
}

.kz {
    background-image: url("/flags/1x1/kz.svg");
}

.kz {
    background-image: url("/flags/1x1/kz.svg");
}

.la {
    background-image: url("/flags/1x1/la.svg");
}

.la {
    background-image: url("/flags/1x1/la.svg");
}

.lb {
    background-image: url("/flags/1x1/lb.svg");
}

.lb {
    background-image: url("/flags/1x1/lb.svg");
}

.lc {
    background-image: url("/flags/1x1/lc.svg");
}

.lc {
    background-image: url("/flags/1x1/lc.svg");
}

.lgbt {
    background-image: url("/flags/1x1/lgbt.svg");
}

.lgbt {
    background-image: url("/flags/1x1/lgbt.svg");
}

.li {
    background-image: url("/flags/1x1/li.svg");
}

.li {
    background-image: url("/flags/1x1/li.svg");
}

.lk {
    background-image: url("/flags/1x1/lk.svg");
}

.lk {
    background-image: url("/flags/1x1/lk.svg");
}

.lr {
    background-image: url("/flags/1x1/lr.svg");
}

.lr {
    background-image: url("/flags/1x1/lr.svg");
}

.ls {
    background-image: url("/flags/1x1/ls.svg");
}

.ls {
    background-image: url("/flags/1x1/ls.svg");
}

.lt {
    background-image: url("/flags/1x1/lt.svg");
}

.lt {
    background-image: url("/flags/1x1/lt.svg");
}

.lu {
    background-image: url("/flags/1x1/lu.svg");
}

.lu {
    background-image: url("/flags/1x1/lu.svg");
}

.lv {
    background-image: url("/flags/1x1/lv.svg");
}

.lv {
    background-image: url("/flags/1x1/lv.svg");
}

.ly {
    background-image: url("/flags/1x1/ly.svg");
}

.ly {
    background-image: url("/flags/1x1/ly.svg");
}

.ma {
    background-image: url("/flags/1x1/ma.svg");
}

.ma {
    background-image: url("/flags/1x1/ma.svg");
}

.mc {
    background-image: url("/flags/1x1/mc.svg");
}

.mc {
    background-image: url("/flags/1x1/mc.svg");
}

.md {
    background-image: url("/flags/1x1/md.svg");
}

.md {
    background-image: url("/flags/1x1/md.svg");
}

.me {
    background-image: url("/flags/1x1/me.svg");
}

.me {
    background-image: url("/flags/1x1/me.svg");
}

.mf {
    background-image: url("/flags/1x1/mf.svg");
}

.mf {
    background-image: url("/flags/1x1/mf.svg");
}

.mg {
    background-image: url("/flags/1x1/mg.svg");
}

.mg {
    background-image: url("/flags/1x1/mg.svg");
}

.mh {
    background-image: url("/flags/1x1/mh.svg");
}

.mh {
    background-image: url("/flags/1x1/mh.svg");
}

.mk {
    background-image: url("/flags/1x1/mk.svg");
}

.mk {
    background-image: url("/flags/1x1/mk.svg");
}

.ml {
    background-image: url("/flags/1x1/ml.svg");
}

.ml {
    background-image: url("/flags/1x1/ml.svg");
}

.mm {
    background-image: url("/flags/1x1/mm.svg");
}

.mm {
    background-image: url("/flags/1x1/mm.svg");
}

.mn {
    background-image: url("/flags/1x1/mn.svg");
}

.mn {
    background-image: url("/flags/1x1/mn.svg");
}

.mo {
    background-image: url("/flags/1x1/mo.svg");
}

.mo {
    background-image: url("/flags/1x1/mo.svg");
}

.mp {
    background-image: url("/flags/1x1/mp.svg");
}

.mp {
    background-image: url("/flags/1x1/mp.svg");
}

.mq {
    background-image: url("/flags/1x1/mq.svg");
}

.mq {
    background-image: url("/flags/1x1/mq.svg");
}

.mr {
    background-image: url("/flags/1x1/mr.svg");
}

.mr {
    background-image: url("/flags/1x1/mr.svg");
}

.ms {
    background-image: url("/flags/1x1/ms.svg");
}

.ms {
    background-image: url("/flags/1x1/ms.svg");
}

.mt {
    background-image: url("/flags/1x1/mt.svg");
}

.mt {
    background-image: url("/flags/1x1/mt.svg");
}

.mu {
    background-image: url("/flags/1x1/mu.svg");
}

.mu {
    background-image: url("/flags/1x1/mu.svg");
}

.mv {
    background-image: url("/flags/1x1/mv.svg");
}

.mv {
    background-image: url("/flags/1x1/mv.svg");
}

.mw {
    background-image: url("/flags/1x1/mw.svg");
}

.mw {
    background-image: url("/flags/1x1/mw.svg");
}

.mx {
    background-image: url("/flags/1x1/mx.svg");
}

.mx {
    background-image: url("/flags/1x1/mx.svg");
}

.my {
    background-image: url("/flags/1x1/my.svg");
}

.my {
    background-image: url("/flags/1x1/my.svg");
}

.mz {
    background-image: url("/flags/1x1/mz.svg");
}

.mz {
    background-image: url("/flags/1x1/mz.svg");
}

.na {
    background-image: url("/flags/1x1/na.svg");
}

.na {
    background-image: url("/flags/1x1/na.svg");
}

.nc {
    background-image: url("/flags/1x1/nc.svg");
}

.nc {
    background-image: url("/flags/1x1/nc.svg");
}

.ne {
    background-image: url("/flags/1x1/ne.svg");
}

.ne {
    background-image: url("/flags/1x1/ne.svg");
}

.nf {
    background-image: url("/flags/1x1/nf.svg");
}

.nf {
    background-image: url("/flags/1x1/nf.svg");
}

.ng {
    background-image: url("/flags/1x1/ng.svg");
}

.ng {
    background-image: url("/flags/1x1/ng.svg");
}

.ni {
    background-image: url("/flags/1x1/ni.svg");
}

.ni {
    background-image: url("/flags/1x1/ni.svg");
}

.nl {
    background-image: url("/flags/1x1/nl.svg");
}

.nl {
    background-image: url("/flags/1x1/nl.svg");
}

.no {
    background-image: url("/flags/1x1/no.svg");
}

.no {
    background-image: url("/flags/1x1/no.svg");
}

.np {
    background-image: url("/flags/1x1/np.svg");
}

.np {
    background-image: url("/flags/1x1/np.svg");
}

.nr {
    background-image: url("/flags/1x1/nr.svg");
}

.nr {
    background-image: url("/flags/1x1/nr.svg");
}

.nu {
    background-image: url("/flags/1x1/nu.svg");
}

.nu {
    background-image: url("/flags/1x1/nu.svg");
}

.nz {
    background-image: url("/flags/1x1/nz.svg");
}

.nz {
    background-image: url("/flags/1x1/nz.svg");
}

.om {
    background-image: url("/flags/1x1/om.svg");
}

.om {
    background-image: url("/flags/1x1/om.svg");
}

.pa {
    background-image: url("/flags/1x1/pa.svg");
}

.pa {
    background-image: url("/flags/1x1/pa.svg");
}

.pe {
    background-image: url("/flags/1x1/pe.svg");
}

.pe {
    background-image: url("/flags/1x1/pe.svg");
}

.pf {
    background-image: url("/flags/1x1/pf.svg");
}

.pf {
    background-image: url("/flags/1x1/pf.svg");
}

.pg {
    background-image: url("/flags/1x1/pg.svg");
}

.pg {
    background-image: url("/flags/1x1/pg.svg");
}

.ph {
    background-image: url("/flags/1x1/ph.svg");
}

.ph {
    background-image: url("/flags/1x1/ph.svg");
}

.pk {
    background-image: url("/flags/1x1/pk.svg");
}

.pk {
    background-image: url("/flags/1x1/pk.svg");
}

.pl {
    background-image: url("/flags/1x1/pl.svg");
}

.pl {
    background-image: url("/flags/1x1/pl.svg");
}

.pm {
    background-image: url("/flags/1x1/pm.svg");
}

.pm {
    background-image: url("/flags/1x1/pm.svg");
}

.pn {
    background-image: url("/flags/1x1/pn.svg");
}

.pn {
    background-image: url("/flags/1x1/pn.svg");
}

.pr {
    background-image: url("/flags/1x1/pr.svg");
}

.pr {
    background-image: url("/flags/1x1/pr.svg");
}

.ps {
    background-image: url("/flags/1x1/ps.svg");
}

.ps {
    background-image: url("/flags/1x1/ps.svg");
}

.pt {
    background-image: url("/flags/1x1/pt.svg");
}

.pt {
    background-image: url("/flags/1x1/pt.svg");
}

.pw {
    background-image: url("/flags/1x1/pw.svg");
}

.pw {
    background-image: url("/flags/1x1/pw.svg");
}

.py {
    background-image: url("/flags/1x1/py.svg");
}

.py {
    background-image: url("/flags/1x1/py.svg");
}

.qa {
    background-image: url("/flags/1x1/qa.svg");
}

.qa {
    background-image: url("/flags/1x1/qa.svg");
}

.re {
    background-image: url("/flags/1x1/re.svg");
}

.re {
    background-image: url("/flags/1x1/re.svg");
}

.ro {
    background-image: url("/flags/1x1/ro.svg");
}

.ro {
    background-image: url("/flags/1x1/ro.svg");
}

.rs {
    background-image: url("/flags/1x1/rs.svg");
}

.rs {
    background-image: url("/flags/1x1/rs.svg");
}

.ru {
    background-image: url("/flags/1x1/ru.svg");
}

.ru {
    background-image: url("/flags/1x1/ru.svg");
}

.rw {
    background-image: url("/flags/1x1/rw.svg");
}

.rw {
    background-image: url("/flags/1x1/rw.svg");
}

.sa {
    background-image: url("/flags/1x1/sa.svg");
}

.sa {
    background-image: url("/flags/1x1/sa.svg");
}

.sb {
    background-image: url("/flags/1x1/sb.svg");
}

.sb {
    background-image: url("/flags/1x1/sb.svg");
}

.sc {
    background-image: url("/flags/1x1/sc.svg");
}

.sc {
    background-image: url("/flags/1x1/sc.svg");
}

.sd {
    background-image: url("/flags/1x1/sd.svg");
}

.sd {
    background-image: url("/flags/1x1/sd.svg");
}

.se {
    background-image: url("/flags/1x1/se.svg");
}

.se {
    background-image: url("/flags/1x1/se.svg");
}

.sg {
    background-image: url("/flags/1x1/sg.svg");
}

.sg {
    background-image: url("/flags/1x1/sg.svg");
}

.sh {
    background-image: url("/flags/1x1/sh.svg");
}

.sh {
    background-image: url("/flags/1x1/sh.svg");
}

.si {
    background-image: url("/flags/1x1/si.svg");
}

.si {
    background-image: url("/flags/1x1/si.svg");
}

.sj {
    background-image: url("/flags/1x1/sj.svg");
}

.sj {
    background-image: url("/flags/1x1/sj.svg");
}

.sk {
    background-image: url("/flags/1x1/sk.svg");
}

.sk {
    background-image: url("/flags/1x1/sk.svg");
}

.sl {
    background-image: url("/flags/1x1/sl.svg");
}

.sl {
    background-image: url("/flags/1x1/sl.svg");
}

.sm {
    background-image: url("/flags/1x1/sm.svg");
}

.sm {
    background-image: url("/flags/1x1/sm.svg");
}

.sn {
    background-image: url("/flags/1x1/sn.svg");
}

.sn {
    background-image: url("/flags/1x1/sn.svg");
}

.so {
    background-image: url("/flags/1x1/so.svg");
}

.so {
    background-image: url("/flags/1x1/so.svg");
}

.sr {
    background-image: url("/flags/1x1/sr.svg");
}

.sr {
    background-image: url("/flags/1x1/sr.svg");
}

.ss {
    background-image: url("/flags/1x1/ss.svg");
}

.ss {
    background-image: url("/flags/1x1/ss.svg");
}

.st {
    background-image: url("/flags/1x1/st.svg");
}

.st {
    background-image: url("/flags/1x1/st.svg");
}

.sv {
    background-image: url("/flags/1x1/sv.svg");
}

.sv {
    background-image: url("/flags/1x1/sv.svg");
}

.sx {
    background-image: url("/flags/1x1/sx.svg");
}

.sx {
    background-image: url("/flags/1x1/sx.svg");
}

.sy {
    background-image: url("/flags/1x1/sy.svg");
}

.sy {
    background-image: url("/flags/1x1/sy.svg");
}

.sz {
    background-image: url("/flags/1x1/sz.svg");
}

.sz {
    background-image: url("/flags/1x1/sz.svg");
}

.ta {
    background-image: url("/flags/1x1/ta.svg");
}

.ta {
    background-image: url("/flags/1x1/ta.svg");
}

.tc {
    background-image: url("/flags/1x1/tc.svg");
}

.tc {
    background-image: url("/flags/1x1/tc.svg");
}

.td {
    background-image: url("/flags/1x1/td.svg");
}

.td {
    background-image: url("/flags/1x1/td.svg");
}

.tf {
    background-image: url("/flags/1x1/tf.svg");
}

.tf {
    background-image: url("/flags/1x1/tf.svg");
}

.tg {
    background-image: url("/flags/1x1/tg.svg");
}

.tg {
    background-image: url("/flags/1x1/tg.svg");
}

.th {
    background-image: url("/flags/1x1/th.svg");
}

.th {
    background-image: url("/flags/1x1/th.svg");
}

.tj {
    background-image: url("/flags/1x1/tj.svg");
}

.tj {
    background-image: url("/flags/1x1/tj.svg");
}

.tk {
    background-image: url("/flags/1x1/tk.svg");
}

.tk {
    background-image: url("/flags/1x1/tk.svg");
}

.tl {
    background-image: url("/flags/1x1/tl.svg");
}

.tl {
    background-image: url("/flags/1x1/tl.svg");
}

.tm {
    background-image: url("/flags/1x1/tm.svg");
}

.tm {
    background-image: url("/flags/1x1/tm.svg");
}

.tn {
    background-image: url("/flags/1x1/tn.svg");
}

.tn {
    background-image: url("/flags/1x1/tn.svg");
}

.to {
    background-image: url("/flags/1x1/to.svg");
}

.to {
    background-image: url("/flags/1x1/to.svg");
}

.tr {
    background-image: url("/flags/1x1/tr.svg");
}

.tr {
    background-image: url("/flags/1x1/tr.svg");
}

.tt {
    background-image: url("/flags/1x1/tt.svg");
}

.tt {
    background-image: url("/flags/1x1/tt.svg");
}

.tv {
    background-image: url("/flags/1x1/tv.svg");
}

.tv {
    background-image: url("/flags/1x1/tv.svg");
}

.tw {
    background-image: url("/flags/1x1/tw.svg");
}

.tw {
    background-image: url("/flags/1x1/tw.svg");
}

.tz {
    background-image: url("/flags/1x1/tz.svg");
}

.tz {
    background-image: url("/flags/1x1/tz.svg");
}

.ua {
    background-image: url("/flags/1x1/ua.svg");
}

.ua {
    background-image: url("/flags/1x1/ua.svg");
}

.ug {
    background-image: url("/flags/1x1/ug.svg");
}

.ug {
    background-image: url("/flags/1x1/ug.svg");
}

.um {
    background-image: url("/flags/1x1/um.svg");
}

.um {
    background-image: url("/flags/1x1/um.svg");
}

.us {
    background-image: url("/flags/1x1/us.svg");
}

.us {
    background-image: url("/flags/1x1/us.svg");
}

.uy {
    background-image: url("/flags/1x1/uy.svg");
}

.uy {
    background-image: url("/flags/1x1/uy.svg");
}

.uz {
    background-image: url("/flags/1x1/uz.svg");
}

.uz {
    background-image: url("/flags/1x1/uz.svg");
}

.va {
    background-image: url("/flags/1x1/va.svg");
}

.va {
    background-image: url("/flags/1x1/va.svg");
}

.vc {
    background-image: url("/flags/1x1/vc.svg");
}

.vc {
    background-image: url("/flags/1x1/vc.svg");
}

.ve {
    background-image: url("/flags/1x1/ve.svg");
}

.ve {
    background-image: url("/flags/1x1/ve.svg");
}

.vg {
    background-image: url("/flags/1x1/vg.svg");
}

.vg {
    background-image: url("/flags/1x1/vg.svg");
}

.vi {
    background-image: url("/flags/1x1/vi.svg");
}

.vi {
    background-image: url("/flags/1x1/vi.svg");
}

.vn {
    background-image: url("/flags/1x1/vn.svg");
}

.vn {
    background-image: url("/flags/1x1/vn.svg");
}

.vu {
    background-image: url("/flags/1x1/vu.svg");
}

.vu {
    background-image: url("/flags/1x1/vu.svg");
}

.wf {
    background-image: url("/flags/1x1/wf.svg");
}

.wf {
    background-image: url("/flags/1x1/wf.svg");
}

.ws {
    background-image: url("/flags/1x1/ws.svg");
}

.ws {
    background-image: url("/flags/1x1/ws.svg");
}

.xk {
    background-image: url("/flags/1x1/xk.svg");
}

.xk {
    background-image: url("/flags/1x1/xk.svg");
}

.ye {
    background-image: url("/flags/1x1/ye.svg");
}

.ye {
    background-image: url("/flags/1x1/ye.svg");
}

.yt {
    background-image: url("/flags/1x1/yt.svg");
}

.yt {
    background-image: url("/flags/1x1/yt.svg");
}

.za {
    background-image: url("/flags/1x1/za.svg");
}

.za {
    background-image: url("/flags/1x1/za.svg");
}

.zm {
    background-image: url("/flags/1x1/zm.svg");
}

.zm {
    background-image: url("/flags/1x1/zm.svg");
}

.zw {
    background-image: url("/flags/1x1/zw.svg");
}

.zw {
    background-image: url("/flags/1x1/zw.svg");
}
