// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    .navigation {
        .swiper-button-next,
        .swiper-button-prev {
            @apply text-gray-300  transition-all;
            &:hover {
                @apply text-red-300;
            }
        }
    }
}

html,
body,
#__next {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
}

* {
    font-family: "Poppins", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
        "Open Sans", "Helvetica Neue", sans-serif;
    box-sizing: inherit;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

*::selection {
    @apply bg-red-550/60;
}

input,
textarea {
    outline: none;
    appearance: none;
    box-shadow: none !important;
}

// remove spingbox on input number
input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.fade-in {
    animation-name: fadeIn;
    animation-duration: 0.25s;
    animation-fill-mode: forwards;
}

.fade-in-delay {
    animation-name: fadeIn;
    animation-duration: 0.25s;
    animation-delay: 0.4s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dot {
    @apply bg-red-550;
    box-sizing: content-box;
    margin-left: 2px;
    display: inline-block;
    width: 0.275em;
    height: 0.275em;
    border-radius: 50%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: box-shadow 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-name: breathe;
    animation-duration: 1s;
    animation-delay: 4s;
    animation-iteration-count: 2;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes breathe {
    from {
        transform: translateY(0px);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    50% {
        transform: translateY(-0.25em);
        box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
    }
    to {
        transform: translateY(0px);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
}

button.animated-svg,
a.animated-svg {
    svg {
        transition: transform 2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:hover,
    &:focus {
        svg {
            transform: translateX(4px);
        }
    }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    input[type="checkbox"],
    input[type="radio"] {
        --active: #000000;
        --active-inner: #fff;
        --focus: 4px rgba(0, 0, 0, 0.3);
        --border: #000000;
        --border-hover: #000000;
        --background: #fff;
        --disabled: #f6f8ff;
        --disabled-inner: #e1e6f9;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 21px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        margin: 0;
        cursor: pointer;
        background: var(--b, var(--background));
        transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
        &:after {
            content: "";
            position: relative;
            bottom: 4px;
            right: 2px;
            width: 21px;
            height: 21px;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            transition: color 100ms;
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: 0.3s;
            --d-t: 0.6s;
            --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: 0.9;
            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }
            & + label {
                cursor: not-allowed;
            }
        }
        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--border-hover);
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 21px;
            &:after {
                opacity: var(--o, 0);
            }
            &:checked {
                --o: 1;
            }
        }
        & + label {
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            vertical-align: top;
            cursor: pointer;
            margin-left: 4px;
        }
    }
    input[type="checkbox"] {
        &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }
            &:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }
            &:disabled {
                &:not(:checked) {
                    &:after {
                        opacity: 0.6;
                    }
                }
            }
        }
    }
    // radio input
    input[type="radio"] {
        border-radius: 50%;
        border: 2px solid red;
        &:checked {
            background-color: black!important;
            background-image: none!important;
            &:after {
                border: 2px solid black;
                outline: 3px solid white;
                outline-offset: -5px;
            }
        }
    }
}

.gradient-gray {
    // background-color: #fff;
    // background-image: linear-gradient(120deg, #ebeced, #eff0f3, #edecec, #efeeef, #f5f4f2, #ece9e6, #dedfde);
    background: rgb(240, 240, 240);
    background: radial-gradient(ellipse at top left, rgba(250, 250, 250, 1) 0%, rgba(245, 245, 245, 1) 57%);
}

:root {
    --p: 259 94% 51%;
    --pf: 259 94% 41%;
    --sf: 314 100% 38%;
    --af: 174 60% 41%;
    --nf: 219 14% 22%;
    --in: 198 93% 60%;
    --su: 158 64% 52%;
    --wa: 43 96% 56%;
    --er: 0 91% 71%;
    --inc: 198 100% 12%;
    --suc: 158 100% 10%;
    --wac: 43 100% 11%;
    --erc: 0 100% 14%;
    --rounded-box: 1rem;
    --rounded-btn: 0.5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: 0.25s;
    --animation-input: 0.2s;
    --btn-text-case: uppercase;
    --btn-focus-scale: 0.95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: 0.5rem;
    --pc: 0 0% 100%;
    --s: 314 100% 47%;
    --sc: 0 0% 100%;
    --a: 174 60% 51%;
    --ac: 175 44% 15%;
    --n: 219 14% 28%;
    --nc: 0 0% 100%;
    --b1: 0 0% 100%;
    --b2: 0 0% 95%;
    --b3: 180 2% 90%;
    --bc: 215 28% 17%;
    
    //swiper
    --swiper-pagination-color: rgba(228, 0, 42);
    --swiper-pagination-bullet-inactive-color: rgb(107, 114, 128);
    --swiper-pagination-bullet-inactive-opacity: 0.8;
    --swiper-pagination-bullet-size: 0.5rem;  
}

.range {
    height: 1.5rem;
    width: 100%;
    cursor: pointer;
    -webkit-appearance: none;
    --range-shdw: var(--bc);
    overflow: hidden;
    background-color: transparent;
    border-radius: var(--rounded-box, 1rem);
    &:focus {
        outline: none;
    }
    &:focus-visible::-webkit-slider-thumb {
        --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
    }
    &:focus-visible::-moz-range-thumb {
        --focus-shadow: 0 0 0 6px hsl(var(--b1)) inset, 0 0 0 2rem hsl(var(--range-shdw)) inset;
    }
    &::-webkit-slider-runnable-track {
        height: 0.5rem;
        width: 100%;
        border-radius: var(--rounded-box, 1rem);
        background-color: hsla(var(--bc) / 0.1);
    }
    &::-moz-range-track {
        height: 0.5rem;
        width: 100%;
        border-radius: var(--rounded-box, 1rem);
        background-color: hsla(var(--bc) / 0.1);
    }
    &::-webkit-slider-thumb {
        background-color: hsl(var(--b1));
        position: relative;
        height: 1.5rem;
        width: 1.5rem;
        border-style: none;
        border-radius: var(--rounded-box, 1rem);
        -webkit-appearance: none;
        top: 50%;
        color: hsl(var(--range-shdw));
        transform: translateY(-50%);
        --filler-size: 100rem;
        --filler-offset: 0.6rem;
        box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0),
            calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
    }
    &::-moz-range-thumb {
        background-color: hsl(var(--b1));
        position: relative;
        height: 1.5rem;
        width: 1.5rem;
        border-style: none;
        border-radius: var(--rounded-box, 1rem);
        top: 50%;
        color: hsl(var(--range-shdw));
        --filler-size: 100rem;
        --filler-offset: 0.5rem;
        box-shadow: 0 0 0 3px hsl(var(--range-shdw)) inset, var(--focus-shadow, 0 0),
            calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
    }
}

.range-primary {
    --range-shdw: var(--p);
}

.range-secondary {
    --range-shdw: var(--s);
}

.range-accent {
    --range-shdw: var(--a);
}

.rating input {
    appearance: none;
    -webkit-appearance: none;
}

.range-xs {
    height: 1rem;
}

.range-xs::-webkit-slider-runnable-track {
    height: 0.25rem;
}

.range-xs::-moz-range-track {
    height: 0.25rem;
}

.range-xs::-webkit-slider-thumb {
    height: 1rem;
    width: 1rem;
    --filler-offset: 0.4rem;
}

.range-xs::-moz-range-thumb {
    height: 1rem;
    width: 1rem;
    --filler-offset: 0.4rem;
}

.range-sm {
    height: 1.25rem;
}

.range-sm::-webkit-slider-runnable-track {
    height: 0.25rem;
}

.range-sm::-moz-range-track {
    height: 0.25rem;
}

.range-sm::-webkit-slider-thumb {
    height: 1.25rem;
    width: 1.25rem;
    --filler-offset: 0.5rem;
}

.range-sm::-moz-range-thumb {
    height: 1.25rem;
    width: 1.25rem;
    --filler-offset: 0.5rem;
}

.range-md {
    height: 1.5rem;
}

.range-md::-webkit-slider-runnable-track {
    height: 0.5rem;
}

.range-md::-moz-range-track {
    height: 0.5rem;
}

.range-md::-webkit-slider-thumb {
    height: 1.5rem;
    width: 1.5rem;
    --filler-offset: 0.6rem;
}

.range-md::-moz-range-thumb {
    height: 1.5rem;
    width: 1.5rem;
    --filler-offset: 0.6rem;
}

.range-lg {
    height: 2rem;
}

.range-lg::-webkit-slider-runnable-track {
    height: 1rem;
}

.range-lg::-moz-range-track {
    height: 1rem;
}

.range-lg::-webkit-slider-thumb {
    height: 2rem;
    width: 2rem;
    --filler-offset: 1rem;
}

.range-lg::-moz-range-thumb {
    height: 2rem;
    width: 2rem;
    --filler-offset: 1rem;
}

.underline-b {
    position: relative;
    transition: all 0.2s ease-in;
    &::after {
        position: absolute;
        content: "";
        left: -2px;
        bottom: -2px;
        width: calc(100% + 4px);
        height: 3px;
        background: linear-gradient(currentColor 0 0) 0 100% / var(--d, 0) 3px no-repeat;
        transition: 0.5s;
    }
    &:hover::after {
        --d: 100%;
    }
}

input[type="number"] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.tooltip-arrow {
    height: 1rem;
    position: absolute;
    width: 1rem;
    pointer-events: none;
}

.tooltip-arrow::before {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    width: 0;
}

.tooltip-arrow::after {
    border-style: solid;
    content: "";
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::before {
    border-color: transparent var(--tooltipBorder) transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::after {
    border-color: transparent var(--tooltipBackground) transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
    left: 6px;
    top: 0;
}

button,
a {
    outline: none;
    &:focus,
    &:active,
    &:focus-visible {
        outline: none;
    }
}

.br-margin {
    br {
        margin-bottom: 0.5em;
    }
}

.paragraph-margin {
    p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0.5em;
    }
}

input[type="password"] {
    ::-ms-reveal {
        display: none;
    }
}

// media print

@media print {
    body {
        font-size: 11px;
    }

    .text-base {
        font-size: 11px;
    }
    .text-2xl,
    .text-4xl {
        font-size: 16px;
        line-height: 115%;
    }
    .text-lg {
        font-size: 12px;
        line-height: 115%;
    }

    .w-8 {
        width: 24px;
    }
    .h-8 {
        height: 24px;
    }
    .pt-10,
    .md\:pt-10 {
        padding-top: 10px;
    }
    .pb-10,
    .md\:pb-10 {
        padding-bottom: 10px;
    }
    .py-10,
    .py-12,
    .md\:py-10,
    .md\:py-12 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .pt-20,
    .md\:pt-20 {
        padding-top: 10px;
    }
    .pb-20,
    .md\:pb-20 {
        padding-bottom: 5rem;
    }
    .py-20,
    .md\:py-20 {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    .mb-8 {
        margin-bottom: 1rem;
    }
    .py-5 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .gap-y-5 {
        row-gap: 10px;
    }
    .gap-4 {
        column-gap: 10px;
        row-gap: 10px;
    }

    .fp.fp-md {
        line-height: 12px !important;
        width: 12px !important;
    }

    .p-2 {
        padding: 4px 8px;
    }

    .px-4 {
        padding-left: 8px;
        padding-right: 8px;
    }
    .py-2 {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}
.exact-print-color{
    print-color-adjust: exact
}
